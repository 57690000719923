/* adapted from sanitize.css */

*,
:before,
:after {
  background-repeat: no-repeat;
  box-sizing: inherit;
}

:before,
:after {
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  box-sizing: border-box;
  cursor: default;
  -ms-text-size-adjust: 100%; /* 3 */
  -webkit-text-size-adjust: 100%; /* 3 */
}

body {
  margin: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

nav ol,
nav ul {
  list-style: none;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

::-moz-selection {
  background-color: #b3d4fc;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
}

button,
input,
select,
textarea {
  background-color: transparent;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

/* ARIA (https://w3c.github.io/html-aria/)
    ========================================================================== */

/**
  * Change the cursor on busy elements (opinionated).
  */

[aria-busy='true'] {
  cursor: progress;
}

/*
  * Change the cursor on control elements (opinionated).
  */

[aria-controls] {
  cursor: pointer;
}

/*
  * Change the display on visually hidden accessible elements (opinionated).
  */

[aria-hidden='false'][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}

/*
  * Change the cursor on disabled, not-editable, or otherwise
  * inoperable elements (opinionated).
  */

[aria-disabled] {
  cursor: default;
}
