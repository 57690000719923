/* Size */
.header-w-7,
.header-h-7 {
  width: 28px;
}

.header-w-72 {
  width: 288px;
}

.header-px-5 {
  padding-left: 20px;
  padding-right: 20px;
}

/* Background */
.header-bg-white {
  background-color: #fff;
}

.header-text-black-ultra {
  color: #1d1d1d;
}

.header-text-black-main {
  color: rgb(65 65 65);
}

/* Container */
.header-container {
  width: 100%;
  height: 48px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 576px) {
  .header-container {
    max-width: 576px;
    height: 56px;
  }
}
@media (min-width: 768px) {
  .header-container {
    max-width: 768px;
    width: 744px;
  }
}
@media (min-width: 992px) {
  .header-container {
    max-width: 992px;
  }
}
@media (min-width: 996px) {
  .header-container {
    width: 984px;
  }
}
@media (min-width: 1200px) {
  .header-container {
    max-width: 1200px;
    width: 1176px;
  }
}
@media (min-width: 1400px) {
  .header-container {
    max-width: 1400px;
  }
}

/* Display */
.header-flex {
  display: flex;
}
.header-hidden {
  display: none;
}

/* Width */
.header-w-full {
  width: 100%;
}

/* Margin */
.header-mr-2 {
  margin-right: 0.5rem;
}

.-header-mt-4 {
  margin-top: -16px;
}

/* Whitespace */
.header-whitespace-nowrap {
  white-space: nowrap;
}

/* Left side of Header */
.header__left_wrapper {
  display: flex;
  align-items: center;
  position: relative;

  justify-content: space-between;

  padding: 0 12px;
}

.header__logo,
#SiteLogo {
  display: flex;
  align-items: center;
}

.header__logo_container {
  display: flex;
  align-items: center;
}

.header__logo_container > svg {
  width: 40px;
  height: 40px;
}

.header__logo_text_wrapper {
  flex-direction: column;
  margin-left: 0.25rem;
}

.header__logo_text {
  font-size: 16px;
  line-height: 16px;

  color: #1d1d1d;
}

.header__logo_text--small {
  font-size: 10px;
  line-height: 10px;
  margin-top: 4px;
}

.header__lang_settings_button {
  border-width: 0px;
  outline: none;
  padding: 0;
  margin-right: 0.25rem;
}

.header-holidu-logo {
  margin-left: 4px;
  height: 16px;
}

/* SM */
@media (min-width: 576px) {
  .header-holidu-logo {
    margin-left: 6px;
    height: 20px;
  }

  .header__logo {
    margin-left: 0;
    margin-right: 0;
  }

  .header__logo_text_wrapper {
    margin-left: 1rem;

    display: flex;
  }

  .header__logo_text {
    display: inline;
    font-size: 18px;
  }

  .header__logo_text--small {
    font-size: 12px;
    line-height: 12px;
  }
}

/* LG */
@media (min-width: 992px) {
  .header__left_wrapper {
    width: 33.33333333%;
    padding: 0;
  }

  .header__logo_text {
    font-size: 20px;
    line-height: 20px;
    color: #1d1d1d;
  }

  .header__buttons_wrapper {
    display: none;
  }
}

/* Right side of Header */

.header__right_wrapper {
  display: none;
  width: 66.66666667%;
  justify-content: flex-end;
  position: relative;
}

@media (min-width: 992px) {
  .header__right_wrapper {
    display: flex;
  }
}

.mobile-navigation-button-container {
  width: 100%;

  margin-top: 24px;
  display: flex;
  flex-direction: column;
}

.mobile-navigation-button {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.mobile-navigation-button > button {
  color: #1d1d1d;
  font-weight: 700;
  font-size: 18px;
}

a.mobile-navigation-button {
  color: #1d1d1d;
  font-size: 16px;
}

.mobile-navigation-header {
  width: 100%;
  border-bottom: 1px solid #cccccc;

  padding-bottom: 8px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-navigation-close-button {
  display: flex;
  align-items: center;
  justify-content: center;

  border: none;

  width: 40px;
  height: 40px;
}

.mobile-navigation-bottom-notice {
  position: absolute;
  bottom: 16px;
  left: 0;
  right: 0;

  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mobile-navigation-bottom-notice > .header__logo_text--small {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 8px;
}

.mobile-navigation__logo {
  height: 32px;
}

.header__navigation_ul {
  justify-content: flex-end;
  margin: 0;
  padding: 0;
}

.header__navigation_item {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;

  transition-property: border-color;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  font-size: 18px;
}

.header__navigation_item:hover {
  border-color: rgb(0 133 173);
}

.header__navigation_dropdown {
  position: relative;
  width: 100%;
  height: 100%;
}

.header__navigation_link {
  font-weight: 300;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #1d1d1d;
  font-size: 16px;
}

.header__chevron-right {
  height: 16px;
  width: 16px;
  margin-left: 8px;
  color: #1d1d1d;
}

.header__dropdown_menu-wrapper {
  position: absolute;
  visibility: hidden;
  z-index: 500;
  opacity: 0;
  transition-property: visibility, opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  border-radius: 12px;
  top: calc(100% + 4px);
  right: -4px;
  background-color: white;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
}
.header-dropdown:hover .header__dropdown_menu-wrapper,
.settings-dropdown:hover .header__dropdown_menu-wrapper {
  visibility: visible;
  opacity: 1;
}

.dropdown_menu-container {
  width: 100%;
  list-style-type: none;
  background-color: #fff;
  padding: 8px 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
}

.dropdown_menu-container > li:not(:last-of-type) > .dropdown-link {
  border-bottom: 1px solid #dadada;
}

.settings-dropdown {
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  .site-settings-list > li:hover {
    background-color: #eeeeee;
  }
}

.dropdown_menu-container > li:hover {
  background-color: #eeeeee;
}

.dropdown-link {
  width: 100%;
  height: 100%;
  color: #1d1d1d;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 16px;
  display: flex;
  align-items: center;

  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.site-settings-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.mobile-burger__chevron {
  height: 16px;
  width: 16px;
  margin-left: 16px;
}

.mobile-burger-overlay {
  z-index: 510;

  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  justify-content: flex-end;

  margin: 0;

  transform: translateX(100%);
}

.mobile-burger-overlay__open {
  transform: translateX(0);

  transition: all 0.3s ease-in-out;
}

.mobile-portal {
  z-index: 500;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  margin: 0;
  background: rgb(29, 29, 29, 0.75);

  opacity: 0;
  pointer-events: none;
}

.mobile-portal__open {
  opacity: 100%;
  pointer-events: auto;

  transition: all 0.3s ease-in-out;
}

.mobile-sidebar {
  font-size: 14px;
  line-height: 20px;
  width: 100%;

  padding: 8px 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  background: #fff;

  max-width: 320px;
}
