@import '../../../shared/src/tailwind/tailwind.css';

@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind components;

.empty-content {
  content: '';
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 12px;
  padding-right: 12px;
}

@media (min-width: 768px) {
  .container {
    width: 744px;
  }
}

@media (min-width: 996px) {
  .container {
    width: 984px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1176px;
  }
}

.modal-max-h {
  max-height: calc(100vh - 4rem);
}

.footer {
  background: linear-gradient(45deg, #0085ad, #0b9ea5);
}

.home-title {
  text-shadow: 0 2px 1px rgb(41 56 61 / 20%), 0 2px 5px rgb(51 51 51 / 33%),
    0 0 2px rgb(0 0 0 / 55%), 0 0 30px rgb(0 20 45 / 40%);
}

.inspiration-card-title {
  text-shadow: 0 0 21px #17292c;
}

.about-us-content .about-us-title {
  @apply text-black-main my-0 font-light mb-3 text-2xl;
}

.about-us-content ul {
  @apply pl-5;
}

.about-us-content li {
  @apply mb-3;
}

.about-us-content .about-us-intro {
  @apply pb-4;
}
