@import './sanitize.css';

html {
  color: #222;
  font-size: 16px;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif;
  line-height: 1.375;
}

a {
  text-decoration: inherit;
}

input,
button {
  outline: 0;
  border-radius: 0;
  font-family: inherit;
}

div.modal-root  {
  position: relative;
}

body.modal-open {
  overflow: hidden;
}
